<template>
  <div>
    <div class="screenSizeLap">
      <div class="login-main-container">
        <div class="h-100 d-flex align-items-center justify-content-center">
          <!-- do bind the style vth height if the typing test is over -->
          <div class="login-main-sub-container position-relative">
            <b-row class="m-0 w-100">
              <b-col class="p-0">
                <div
                  class="w-100 d-flex justify-content-center align-items-baseline"
                >
                  <!-- <img src="../assets/newimgs/GREYLOGO.svg" alt="" /> -->
                  <img
                    src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/Hire3x_Website_Header.svg"
                    style="
                      display: block;
                      visibility: visible;
                      opacity: 1;
                      height: 40px;
                    "
                    alt=""
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="m-0 w-100" style="height: 95%">
              <b-col class="p-0">
                <div
                  class="d-flex align-items-center flex-column mt-3 text-center completeddetails-container"
                >
                  <div
                    class="h-100 w-75 d-flex justify-content-center flex-column"
                  >
                    <h1 class="login-rightcont-headtxt pt-3">
                      Completed Successfully
                    </h1>

                    <div class="mt-3 h-100">
                      <div class="d-flex align-items-center mt-5 flex-column">
                        <div
                          class="w-50 mt-3 completed-card-container p-5 position-relative"
                        >
                          <div
                            class="cmpltedsucc-icon"
                            style="left: 42%; top: -40px"
                          >
                            <img
                              src="../assets/newui-img/completed.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="d-flex align-items-center flex-column justify-content-center mt-5"
                          >
                            <div class="comp-maintxt">
                              Congratulations! You have successfully completed
                              your assessment test.
                            </div>
                          </div>
                          <div
                            class="mt-5 d-flex align-items-center justify-content-center bluebtns"
                          >
                            <b-button variant="primary" @click="openWebsite"
                              >Okay</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div class="screenSizeMobile">
      <MTclosed />
    </div>
  </div>
</template>
<script>
import MTclosed from "./mobileView/MTclosed.vue";
export default {
  name: "TestClosed",
  components: {
    MTclosed,
  },
  beforeRouteLeave(to, from, next) {
    next(false);
  },
  methods: {
    openWebsite() {
      try {
        location.replace("https://candidate.simplifymycareer.com");
      } catch (error) {
        location.replace("https://candidate.simplifymycareer.com");
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 821px) {
  .screenSizeLap {
    display: none;
  }
}

@media only screen and (min-width: 821px) {
  .screenSizeMobile {
    display: none;
  }
}

.completeddetails-container {
  height: calc(100vh - 123px);
  overflow: auto;
  border-radius: 14px;
  background: url("../assets/newui-img/checkkk.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  width: 100%;
}
.comp-maintxt {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #3a3c40;
}
.comp-subtxt {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #3a3c40;
}
</style>
